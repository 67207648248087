import type { Schemas } from '#shopware'
import type { StatamicNavigationItem } from '~/types/statamic'
import type { CustomerCategory } from '~/types/shopware-custom'
import { useCustomer } from '~/composables/useCustomer'

export function useExclusiveAccess(user: any) {
  const { isProfessional } = useCustomer(user)

  const isExclusive = (
    category: Schemas['Category'] | StatamicNavigationItem
  ) => {
    return (
      'extensions' in category &&
      category?.extensions?.exclusiveCategory?.isExclusiveBrand
    )
  }

  const hasUserBrandAccess = (
    category: Schemas['Category'] | StatamicNavigationItem
  ) =>
    isProfessional.value &&
    isExclusive(category) &&
    user.value?.extensions?.customerCategories?.some(
      (customerCategory: CustomerCategory) =>
        customerCategory.categoryId === category.id
    )

  return {
    isExclusive,
    hasUserBrandAccess,
  }
}
