<template>
  <div
    ref="itemRef"
    class="text-sm1 font-bold text-white lg:text-sm 2xl:text-sm1"
    @mouseenter="openItem"
  >
    <NuxtLink
      v-if="link"
      :to="link"
      class="lined mb-8"
      :class="{
        'after:w-full': isActive,
      }"
    >
      {{ title }}
    </NuxtLink>
    <span
      v-else
      class="lined mb-8 cursor-default"
      :class="{
        'after:w-full': isActive,
      }"
    >
      {{ title }}
    </span>
  </div>
</template>

<script setup lang="ts">
import { getTranslatedProperty } from '@shopware-pwa/helpers-next'
import type { NavigationItem } from '~/stores/navigation'
import item from '~/components/shop/listing/navigation/Item.vue'

const props = defineProps<{
  item: NavigationItem
  seourl?: string
}>()

const navigationDropdown = useNavigationDropdown()
const { active } = storeToRefs(navigationDropdown)
const { getCategoryRoute, buildSeoUrl } = useShopRouter()

const itemRef = ref<HTMLDivElement | undefined>(undefined)

const openItem = () => {
  if (itemRef.value === undefined) {
    navigationDropdown.open(props.item, undefined, undefined)
  } else {
    navigationDropdown.open(
      props.item,
      itemRef.value?.offsetLeft,
      Math.round(itemRef.value?.clientWidth / 2)
    )
  }
}

const isActive = computed(() => {
  if (!active.value || typeof active.value === 'string') {
    return false
  }
  return (
    ('apiAlias' in props.item &&
      'apiAlias' in active.value &&
      active.value?.id === props.item.id) ||
    ('page' in props.item &&
      'page' in active.value &&
      active.value?.page.id === props.item.page.id)
  )
})

const link = computed<string | undefined>(() => {
  if (
    props.seourl &&
    props.item.type === 'link' &&
    props.item.linkType === 'category'
  ) {
    return buildSeoUrl(props.seourl)
  } else if ('apiAlias' in props.item) {
    return getCategoryRoute(props.item)
  } else {
    return props.item.page.url
  }
})

const title = computed(() => {
  if ('apiAlias' in props.item) {
    return getTranslatedProperty(props.item, 'name')
  } else {
    return props.item.page.title
  }
})
</script>
