<template>
  <div>
    <div
      class="flex h-full flex-wrap items-center gap-6 px-8 lg:gap-x-4 2xl:gap-x-6"
    >
      <!-- Shopware Nav Links -->
      <NavItem
        v-for="navItemLevel1 in shopNavigation"
        :key="navItemLevel1.id"
        :item="navItemLevel1"
        :seourl="navItemLevel1.internalLink ? categorySeoUrlMap[navItemLevel1.internalLink] : undefined"
      />
      <!-- CMS Nav Links -->
      <NavItem
        v-for="item in cmsNavigation"
        :key="item?.page?.id"
        :item="item"
      />
    </div>
    <NavDropdown
      v-show="children?.length"
      :items="children"
      :fullwidth="isFullWidth"
      :style="{
        left: offsetLeft,
      }"
      :nipple-offset-left="nippleOffsetLeft"
      @mouseleave="navigationDropdown.close()"
      @mouseenter="navigationDropdown.clearCloseDelay()"
    />
  </div>
</template>

<script setup lang="ts">
import type { NavigationItem } from '~/stores/navigation'

const cms = inject(CMS_GLOBALS)
const shopNavigation = inject(SHOP_GLOBALS)

const navigationDropdown = useNavigationDropdown()

const { children, dropdownNippleX, dropdownX } = storeToRefs(navigationDropdown)

const offsetLeft = computed(() => {
  if (isFullWidth.value) {
    return 0
  }
  if (!dropdownX.value) {
    return 'auto'
  }
  return `${dropdownX.value}px`
})

const nippleOffsetLeft = computed(() => {
  if (dropdownNippleX.value !== undefined) {
    const baseOffset = dropdownNippleX.value - 17 // 1/2 width of nipple
    if (isFullWidth.value && dropdownX.value) {
      return `${dropdownX.value + baseOffset}px`
    } else {
      return `${baseOffset}px`
    }
  }
  return undefined
})

const isFullWidth = computed(() => {
  const childrenWithChildren: NavigationItem[] = children.value?.filter(
    (item) => item.children?.length
  )

  return childrenWithChildren?.length > 3
})

const cmsNavigation = computed(
  () =>
    cms?.value?.main?.tree?.filter(
      ({ page }) => 'is_menu' in page && !page.is_menu
    ) ?? []
)

// We use this as a workaround to get the SEO URLs for internally linked categories
const categorySeoUrlMap = computed(() => {
  const map: Record<string, string> = {}

  const extractSeoUrls = (categories: NavigationItem[] = []) => {
    categories.forEach((category) => {
      if (category.seoUrls?.length) {
        map[category.id] = category.seoUrls[0].seoPathInfo
      }
      if (category.children?.length) {
        extractSeoUrls(category.children)
      }
    })
  }

  extractSeoUrls(shopNavigation.value)

  return map
})
</script>
