<template>
  <div class="fixed inset-0 z-top overflow-y-auto bg-gray-10 pb-32 xl:hidden">
    <Container class="flex h-16 items-center bg-black text-white">
      <button
        class="flex w-full items-center justify-between font-bold"
        @click="$emit('close')"
      >
        <span class="flex items-center">
          <IconAngleLeft class="mr-4 w-4" />
          <span class="text-left"><slot /></span>
        </span>

        <IconClose class="w-5" />
      </button>
    </Container>
    <Container>
      <ul
        class="flex flex-col divide-y divide-gray-100 border-b border-gray-100 pt-4"
      >
        <li v-for="(item, index) in items" :key="index" class="text-black">
          <DropdownAccordion :item="item" :depth="depth" />
        </li>
      </ul>
    </Container>
  </div>
</template>

<script setup lang="ts">
defineProps<{
  items: any[]
  depth?: number
}>()

const emit = defineEmits(['close'])

const route = useRoute()

watch(
  () => route.path,
  () => {
    emit('close')
  }
)
</script>
