<template>
  <div>
    <template v-if="hasChildrenChildren && depth && depth >= 3">
      <MobileMenuNavItem
        :items="item.children"
        class="font-bold"
        :depth="depth"
      >
        {{ item.name }}
      </MobileMenuNavItem>
    </template>
    <Component
      :is="componentType"
      v-else
      :to="hasNoChildren ? link : ''"
      class="flex w-full items-center gap-2 py-4"
      @click="open"
    >
      <template v-if="depth && depth === 3">
        <IconUnlock v-if="hasUserBrandAccess(item)" class="w-4" />
        <IconLock v-else-if="isExclusive(item)" class="w-4" />
      </template>
      <span class="w-full text-left font-bold">
        {{ item.name || item.page?.title }}
      </span>
      <IconAngleDown
        class="w-4 transition-transform duration-300"
        :class="[
          {
            hidden: hasNoChildren,
          },
          { '-rotate-180': isOpen },
        ]"
      />
    </Component>

    <TransitionExpand :open="isOpen">
      <ul class="space-y-4 pb-6 pl-6">
        <li
          v-for="(child, index) in item.children"
          :key="index"
          class="flex gap-2"
        >
          <template v-if="depth && depth === 2">
            <IconUnlock v-if="hasUserBrandAccess(item)" class="w-4" />
            <IconLock v-else-if="isExclusive(item)" class="w-4" />
          </template>
          <NuxtLink
            :to="getCategoryRoute(child) || child?.page?.title"
            class="font-medium"
          >
            {{ child.name || child?.page?.title }}
          </NuxtLink>
        </li>
      </ul>
    </TransitionExpand>
  </div>
</template>

<script setup lang="ts">
import type { NavigationItem } from '~/stores/navigation'
import { useExclusiveAccess } from '~/composables/useExclusiveAccess'

const props = defineProps<{
  item: NavigationItem
  depth?: number
}>()

const { getCategoryRoute } = useShopRouter()
const { user } = useUser()
const { isExclusive, hasUserBrandAccess } = useExclusiveAccess(user)
const isOpen = ref(false)

const hasNoChildren = computed(() => {
  return !props.item.children || props.item.children.length === 0
})

const hasChildrenChildren = computed(() => {
  return props.item.children?.some(
    (child) => child.children && child.children.length > 0
  )
})

const componentType = computed(() => {
  if (hasNoChildren.value) {
    return resolveComponent('NuxtLink')
  }
  return 'button'
})

const link = computed<string | undefined>(() => {
  if ('apiAlias' in props.item) {
    return getCategoryRoute(props.item)
  } else {
    return props.item.page?.url ?? ''
  }
})

const open = () => {
  if (hasNoChildren.value) return

  isOpen.value = !isOpen.value
}
</script>
