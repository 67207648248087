<template>
  <Component
    :is="items.length > 0 ? 'button' : 'div'"
    class="flex w-full items-center justify-between py-3 outline-none focus:outline-none"
    @click="isOpen = !isOpen"
  >
    <!-- Use computed property for icons -->
    <Component
      :is="resolvedIcon"
      v-if="resolvedIcon"
      class="mr-4 w-4 stroke-2"
    />

    <span class="w-full text-left">
      <slot />
    </span>

    <template v-if="items.length > 0">
      <IconAngleRight class="w-4" />

      <ClientOnly>
        <Teleport to="body">
          <Transition name="slide-right-full">
            <MobileMenuNavDropdown
              v-show="isOpen"
              :items="items"
              :depth="depth"
              @close="isOpen = false"
            >
              <slot />
            </MobileMenuNavDropdown>
          </Transition>
        </Teleport>
      </ClientOnly>
    </template>
  </Component>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'

// Import icons explicitly to ensure they are available in SSR
import IconUser from '~/components/icons/User.vue'
import IconHeart from '~/components/icons/Heart.vue'
import IconCart from '~/components/icons/Cart.vue'
import IconSearch from '~/components/icons/Search.vue'

// Define available icons
const icons = {
  user: IconUser,
  heart: IconHeart,
  cart: IconCart,
  search: IconSearch,
}

const props = withDefaults(
  defineProps<{
    icon?: string
    items?: any[]
    depth?: number
  }>(),
  {
    items: () => [],
  }
)

const resolvedIcon = computed(() =>
  props.icon ? icons[props.icon] || null : null
)

const isOpen = ref(false)
</script>
